import { AuthService } from './auth';
import { NotificationService } from './notification';
import { OverviewService } from './overview';
import { ReportService } from './report/index';
import { ReportFilterService } from './report/filter';
import { ReportDashboardABLService } from './report/dashboard_abl';
import { ReportDashboardAssistantService } from './report/dashboard_assistant';
import { ReportDashboardMskService } from './report/dashboard_msk';
import { ReportDashboardSaleService } from './report/dashboard_sale';
import { ReportDashboardCallPlanService } from './report/dashboard_call_plan';
import { ReportDashboardSumCallPlanService } from './report/dashboard_sum_call_plan';
import { CommunicationService } from './communication';
import { CustomerService } from './customer';
import { OffPlanService } from './plan/off_plan';
import { OnPlanService } from './plan/on_plan';
import { ApproveCallPlanService } from './plan/approve';
import { VisitService } from './plan/visit';
import { MorningTalkService } from './morning_talk';
import { WeeklyReportService } from './weekly_report';
import { ManagementService } from './management';
import { ReportDashboardBPlusService } from './report/dashboard_bplus';

const Service = {
  AuthService,
  NotificationService,
  OverviewService,
  ReportService,
  ReportFilterService,
  ReportDashboardABLService,
  ReportDashboardAssistantService,
  ReportDashboardMskService,
  ReportDashboardSaleService,
  ReportDashboardCallPlanService,
  ReportDashboardSumCallPlanService,
  ReportDashboardBPlusService,
  CommunicationService,
  CustomerService,
  OffPlanService,
  OnPlanService,
  ApproveCallPlanService,
  VisitService,
  MorningTalkService,
  WeeklyReportService,
  ManagementService,
};

export default Service;
