import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ServiceStatus } from '@/models/enums/service';
import { mergeObjects } from '@/utils/utils';

import Service from '@/services';
import { FilterModel } from '@/models/classes/customer';
import { CustomerModel } from '@/models/classes/customer/index';
import { CustomerDetailModel } from '@/models/classes/customer/detail';
import { SuccessModel } from '@/models/classes/success';
import { OptionModel } from '@/models/classes/option';

const service = new Service.CustomerService();

// Interface
interface CustomerState {
  customer: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: CustomerDetailModel;
  };

  customers: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data: CustomerModel[];
    options: FilterModel[];
    total: number;
  };
  location: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: SuccessModel;
  };
  options: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    customer: {
      province: any;
      district: any;
      subDistrict: any;
    };
    billing: {
      province: any;
      district: any;
      subDistrict: any;
    };
  };
  groupShopTypes: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    groupTypes: any;
    shopTypes: any;
  };
  BUTypes: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    BUTypes: any;
    subShopTypes: any;
    attributeLv1: any;
    attributeLv2: any;
    attributeLv3: any;
  };
  customerTypes: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    options: OptionModel[];
  };
  store: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: SuccessModel;
  };
  submitOtherOrg: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: SuccessModel;
  };
  updateTaxNo: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: any;
  };
  updateBranch: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: any;
  };
  updatePhone: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: any;
  };
  updateName: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: any;
  };
  updateShopType: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: any;
  };
  updateAddress: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: any;
  };
  updateBu: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: any;
  };

  customerCustomProfile: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: any;
  };
  customerCustomStatus: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: any;
  };
}

// Initialize State
const initialState: CustomerState = {
  customer: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  customers: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: [],
    options: [],
    total: 0,
  },
  location: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  options: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    customer: {
      province: [],
      district: [],
      subDistrict: [],
    },
    billing: {
      province: [],
      district: [],
      subDistrict: [],
    },
  },
  groupShopTypes: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    groupTypes: [],
    shopTypes: [],
  },
  BUTypes: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    BUTypes: [],
    subShopTypes: [],
    attributeLv1: [],
    attributeLv2: [],
    attributeLv3: [],
  },
  customerTypes: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    options: [],
  },
  store: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  submitOtherOrg: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  updateTaxNo: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  updateBranch: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  updatePhone: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  updateName: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  updateShopType: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  updateAddress: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  updateBu: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  customerCustomProfile: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  customerCustomStatus: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  }
};

// Reducer
export const slice = createSlice({
  name: 'Customer',
  initialState,
  reducers: {
    reset: () => initialState,
    patch: (state, action) => mergeObjects({ ...state }, action.payload),
    resetOption: (state) => {
      state.groupShopTypes.groupTypes = [];
      state.groupShopTypes.shopTypes = [];
      state.BUTypes.BUTypes = [];
      state.BUTypes.subShopTypes = [];
      state.BUTypes.attributeLv1 = [];
      state.BUTypes.attributeLv2 = [];
      state.BUTypes.attributeLv3 = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomers.pending, (state) => {
      state.customers.network.code = ServiceStatus.loading;
    });
    builder.addCase(fetchCustomers.fulfilled, (state, action) => {
      state.customers.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.customers.data = action.payload.data;
        state.customers.options = action.payload.options;
      }

      if (action.payload != null && action.payload.total) {
        state.customers.total = action.payload.total;
      }
    });
    builder.addCase(fetchCustomers.rejected, (state, action) => {
      state.customers.network.code = ServiceStatus.failed;
      state.customers.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(fetchMoreCustomers.pending, (state) => { });
    builder.addCase(fetchMoreCustomers.fulfilled, (state, action) => {
      if (action.payload != null && state.customers.data) {
        state.customers.data.push(...action.payload.data);
        if (action.payload != null && action.payload.total) {
          state.customers.total = action.payload.total;
        }
      }
    });
    builder.addCase(fetchMoreCustomers.rejected, (state, action) => { });

    builder.addCase(fetchDetail.pending, (state) => {
      state.customer.network.code = ServiceStatus.loading;
    });
    builder.addCase(fetchDetail.fulfilled, (state, action) => {
      state.customer.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.customer.data = action.payload;
      }
    });
    builder.addCase(fetchDetail.rejected, (state, action) => {
      state.customer.network.code = ServiceStatus.failed;
      state.customer.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onUpdateCustomerLocation.pending, (state) => {
      state.location.network.code = ServiceStatus.loading;
    });
    builder.addCase(onUpdateCustomerLocation.fulfilled, (state, action) => {
      state.location.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.location.data = action.payload;
      }
    });
    builder.addCase(onUpdateCustomerLocation.rejected, (state, action) => {
      state.location.network.code = ServiceStatus.failed;
      state.location.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchProvince.pending, (state) => {
      state.location.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchProvince.fulfilled, (state, action) => {
      state.location.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.options.customer.province = action.payload.data;
        state.options.billing.province = action.payload.data;
        state.options.customer.district = [];
        state.options.customer.subDistrict = [];
        state.options.billing.district = [];
        state.options.billing.subDistrict = [];
      }
    });
    builder.addCase(onFetchProvince.rejected, (state, action) => {
      state.location.network.code = ServiceStatus.failed;
      state.location.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchDistrict.pending, (state) => {
      state.location.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchDistrict.fulfilled, (state, action) => {
      state.location.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.options.customer.district = action.payload.data;
        state.options.billing.district = action.payload.data;
        state.options.customer.subDistrict = [];
        state.options.billing.subDistrict = [];
      }
    });
    builder.addCase(onFetchDistrict.rejected, (state, action) => {
      state.location.network.code = ServiceStatus.failed;
      state.location.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchSubDistrict.pending, (state) => {
      state.location.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchSubDistrict.fulfilled, (state, action) => {
      state.location.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.options.customer.subDistrict = action.payload.data;
        state.options.billing.subDistrict = action.payload.data;
      }
    });
    builder.addCase(onFetchSubDistrict.rejected, (state, action) => {
      state.location.network.code = ServiceStatus.failed;
      state.location.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onPostAddCustomerProfile.pending, (state) => {
      state.store.network.code = ServiceStatus.loading;
    });
    builder.addCase(onPostAddCustomerProfile.fulfilled, (state, action) => {
      console.log("fulfill", action)
      state.store.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.store.data = action.payload;
      }
    });
    builder.addCase(onPostAddCustomerProfile.rejected, (state, action) => {
      console.log("reject", action)
      state.store.network.code = ServiceStatus.failed;
      state.store.network.error = (action.payload as string) || 'Failed to fetch data';
    });
    //MARK: - Group Shop Type
    //---------------- Group Shop Type ------------------------
    builder.addCase(onFetchGetCustomerProfileDropdown.pending, (state) => {
      state.location.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchGetCustomerProfileDropdown.fulfilled, (state, action) => {
      state.location.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        if (action.payload.params.selectedType == 0) {
          state.groupShopTypes.groupTypes = action.payload.apiData?.groupType;
        } else if (action.payload.params.selectedType == 1) {
          state.groupShopTypes.shopTypes = action.payload.apiData?.shopType;
        }
      }
    });
    builder.addCase(onFetchGetCustomerProfileDropdown.rejected, (state, action) => {
      state.location.network.code = ServiceStatus.failed;
      state.location.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchGetAddCustomerDropdown.pending, (state) => {
      state.location.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchGetAddCustomerDropdown.fulfilled, (state, action) => {
      state.location.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload.apiData?.customerType) {
        state.customerTypes.options = action.payload.apiData?.customerType;
      }
    });
    builder.addCase(onFetchGetAddCustomerDropdown.rejected, (state, action) => {
      state.location.network.code = ServiceStatus.failed;
      state.location.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onSubmitCustomerOtherOrg.pending, (state) => {
      state.submitOtherOrg.network.code = ServiceStatus.loading;
    });
    builder.addCase(onSubmitCustomerOtherOrg.fulfilled, (state, action) => {
      state.submitOtherOrg.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload.apiData) {
        state.submitOtherOrg.data = action.payload.apiData;
      }
    });
    builder.addCase(onSubmitCustomerOtherOrg.rejected, (state, action) => {
      state.submitOtherOrg.network.code = ServiceStatus.failed;
      state.submitOtherOrg.network.error = (action.payload as string) || 'Failed to fetch data';
    });
    //MARK: - Group BU Type
    //---------------- Group BU Type ------------------------
    builder.addCase(onFetchGetCustomerTierDropdown.pending, (state) => {
      state.location.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchGetCustomerTierDropdown.fulfilled, (state, action) => {
      state.location.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        if (action.payload.params.selectedType == 0) {
          state.BUTypes.BUTypes = action.payload.apiData?.BUType;
          state.BUTypes.subShopTypes = [];
          state.BUTypes.attributeLv1 = [];
          state.BUTypes.attributeLv2 = [];
          state.BUTypes.attributeLv3 = [];
        } else if (action.payload.params.selectedType == 1) {
          state.BUTypes.subShopTypes = action.payload.apiData?.subShopType;
          state.BUTypes.attributeLv1 = [];
          state.BUTypes.attributeLv2 = [];
          state.BUTypes.attributeLv3 = [];
        } else if (action.payload.params.selectedType == 2) {
          state.BUTypes.attributeLv1 = action.payload.apiData?.attributeLv1;
          state.BUTypes.attributeLv2 = [];
          state.BUTypes.attributeLv3 = [];
        } else if (action.payload.params.selectedType == 3) {
          state.BUTypes.attributeLv2 = action.payload.apiData?.attributeLv2;
          state.BUTypes.attributeLv3 = [];
        } else if (action.payload.params.selectedType == 4) {
          state.BUTypes.attributeLv3 = action.payload.apiData?.attributeLv3;
        }
      }
    });
    builder.addCase(onFetchGetCustomerTierDropdown.rejected, (state, action) => {
      state.location.network.code = ServiceStatus.failed;
      state.location.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onPutUpdateCustomerName.pending, (state, action) => {
      state.updateName.network.code = ServiceStatus.loading;
    });
    builder.addCase(onPutUpdateCustomerName.rejected, (state, action) => {
      state.updateName.network.error = action.error.message;
      state.updateName.network.code = ServiceStatus.failed;
    });
    builder.addCase(onPutUpdateCustomerName.fulfilled, (state, action) => {
      state.updateName.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.updateName.data = action.payload;
      }
    });

    builder.addCase(onPutUpdateCustomerBranch.pending, (state, action) => {
      state.updateBranch.network.code = ServiceStatus.loading;
    });
    builder.addCase(onPutUpdateCustomerBranch.rejected, (state, action) => {
      state.updateBranch.network.error = action.error.message;
      state.updateBranch.network.code = ServiceStatus.failed;
    });
    builder.addCase(onPutUpdateCustomerBranch.fulfilled, (state, action) => {
      // state.updateBranch.data = action.payload.apiSubmit;
      state.updateBranch.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.updateBranch.data = action.payload;
      }
    });

    builder.addCase(onPutUpdateCustomerShopType.pending, (state, action) => {
      state.updateShopType.network.code = ServiceStatus.loading;
    });
    builder.addCase(onPutUpdateCustomerShopType.rejected, (state, action) => {
      state.updateShopType.network.error = action.error.message;
      state.updateShopType.network.code = ServiceStatus.failed;
    });
    builder.addCase(onPutUpdateCustomerShopType.fulfilled, (state, action) => {
      // state.updateShopType.data = action.payload.apiSubmit;
      state.updateShopType.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.updateShopType.data = action.payload;
      }
    });

    builder.addCase(onPutUpdateCustomerTaxNo.pending, (state, action) => {
      state.updateTaxNo.network.code = ServiceStatus.loading;
    });
    builder.addCase(onPutUpdateCustomerTaxNo.rejected, (state, action) => {
      state.updateTaxNo.network.error = action.error.message;
      state.updateTaxNo.network.code = ServiceStatus.failed;
    });
    builder.addCase(onPutUpdateCustomerTaxNo.fulfilled, (state, action) => {
      // state.updateTaxNo.data = action.payload.apiSubmit;
      state.updateTaxNo.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.updateTaxNo.data = action.payload;
      }
    });

    builder.addCase(onPutUpdateCustomerPhone.pending, (state, action) => {
      state.updatePhone.network.code = ServiceStatus.loading;
    });
    builder.addCase(onPutUpdateCustomerPhone.rejected, (state, action) => {
      state.updatePhone.network.error = action.error.message;
      state.updatePhone.network.code = ServiceStatus.failed;
    });
    builder.addCase(onPutUpdateCustomerPhone.fulfilled, (state, action) => {
      // state.updatePhone.data = action.payload.apiSubmit;
      state.updatePhone.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.updatePhone.data = action.payload;
      }
    });

    builder.addCase(onPutUpdateCustomerAddress.pending, (state, action) => {
      state.updateAddress.network.code = ServiceStatus.loading;
    });
    builder.addCase(onPutUpdateCustomerAddress.rejected, (state, action) => {
      state.updateAddress.network.error = action.error.message;
      state.updateAddress.network.code = ServiceStatus.failed;
    });
    builder.addCase(onPutUpdateCustomerAddress.fulfilled, (state, action) => {
      // state.updateAddress.data = action.payload.apiSubmit;
      state.updateAddress.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.updateAddress.data = action.payload;
      }
    });

    builder.addCase(onPutUpdateCustomerBu.pending, (state, action) => {
      state.updateBu.network.code = ServiceStatus.loading;
    });
    builder.addCase(onPutUpdateCustomerBu.rejected, (state, action) => {
      state.updateBu.network.error = action.error.message;
      state.updateBu.network.code = ServiceStatus.failed;
    });
    builder.addCase(onPutUpdateCustomerBu.fulfilled, (state, action) => {
      // state.updateBu.data = action.payload.apiSubmit;
      state.updateBu.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.updateBu.data = action.payload;
      }
    });

    //MARK: - Customer Custom Profile
    //---------------- Customer Custom Profile ------------------------
    builder.addCase(onFetchCustomerCustomProfile.pending, (state) => {
      state.customerCustomProfile.network.code = ServiceStatus.loading;
    });

    builder.addCase(onFetchCustomerCustomProfile.rejected, (state, action) => {
      state.customerCustomProfile.network.code = ServiceStatus.failed;
      state.customerCustomProfile.network.error = (action.payload as string) || 'Failed to fetch data';
    });
    builder.addCase(onFetchCustomerCustomProfile.fulfilled, (state, action) => {
      state.customerCustomProfile.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.customerCustomProfile.data = action.payload;
      }
    });

    builder.addCase(onFetchCustomerCustomStatus.pending, (state) => {
      state.customerCustomStatus.network.code = ServiceStatus.loading;
    });

    builder.addCase(onFetchCustomerCustomStatus.rejected, (state, action) => {
      state.customerCustomStatus.network.code = ServiceStatus.failed;
      state.customerCustomStatus.network.error = (action.payload as string) || 'Failed to fetch data';
    });
    builder.addCase(onFetchCustomerCustomStatus.fulfilled, (state, action) => {
      state.customerCustomStatus.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.customerCustomStatus.data = action.payload;
      }
    });
  },
});

// Service
export const fetchCustomers = createAsyncThunk('customers/list', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchCustomers(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const fetchMoreCustomers = createAsyncThunk('customers/list/more', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchCustomers(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const fetchDetail = createAsyncThunk('customers/detail', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getDetail(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onUpdateCustomerLocation = createAsyncThunk('customers/update/location', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.updateCustomerLocation(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchProvince = createAsyncThunk('customer_profile/address/province', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getSearchProvinceAddress(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchDistrict = createAsyncThunk('customer_profile/address/distinct', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getSearchDistrictAddress(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchSubDistrict = createAsyncThunk('customer_profile/address/subDistinct', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getSearchSubDistrictAddress(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onPostAddCustomerProfile = createAsyncThunk('customer_profile/create', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.postAddCustomerProfile(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    const message = error.response.data.displayMessage ? error.response.data.displayMessage : error.response.data.message
    return thunkAPI.rejectWithValue(message);
  }
});

export const onFetchGetAddCustomerDropdown = createAsyncThunk('customer_profile/add/customer/dropdown', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getAddCustomerDropdown();
    return { apiData };
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchGetCustomerProfileDropdown = createAsyncThunk('customer_profile/shop/group', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getCustomerProfileDropdown(params);
    return { apiData, params };
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchGetCustomerTierDropdown = createAsyncThunk('customer_profile/BU', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getCustomerTierDropdown(params);
    return { apiData, params };
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onSubmitCustomerOtherOrg = createAsyncThunk('customer_profile/submit/customer/other/org', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.submitCustomerOtherOrg(params);
    return { apiData };
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onPutUpdateCustomerName = createAsyncThunk('customer_profile/update/name', async (params: any, thunkAPI) => {
  try {
    const apiData = await service.putUpdateCustomerName(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onPutUpdateCustomerTaxNo = createAsyncThunk('customer_profile/update/taxNo', async (params: any, thunkAPI) => {
  try {
    let apiData = await service.putUpdateCustomerTaxNo(params);

    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onPutUpdateCustomerBranch = createAsyncThunk('customer_profile/update/branch', async (params: any, thunkAPI) => {
  try {
    let apiData = await service.putUpdateCustomerBranch(params);

    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onPutUpdateCustomerPhone = createAsyncThunk('customer_profile/update/phone', async (params: any, thunkAPI) => {
  try {
    let apiData = await service.putUpdateCustomerPhone(params);

    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onPutUpdateCustomerShopType = createAsyncThunk('customer_profile/update/ShopType', async (params: any, thunkAPI) => {
  try {
    let apiData = await service.putUpdateCustomerShopType(params);

    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onPutUpdateCustomerAddress = createAsyncThunk('customer_profile/update/address', async (params: any, thunkAPI) => {
  try {
    let apiData = await service.putUpdateCustomerAddress(params);

    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onPutUpdateCustomerBu = createAsyncThunk('customer_profile/update/BU', async (params: any, thunkAPI) => {
  try {
    let apiData = await service.putUpdateCustomerBu(params);

    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

//MARK: - Custom Profile
export const onFetchCustomerCustomProfile = createAsyncThunk('customer_profile/custom', async (params: any, thunkAPI) => {
  try {
    let apiData = await service.getCustomerCustomProfile(params);

    return apiData;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchCustomerCustomStatus = createAsyncThunk('customer_profile/custom/status', async (params: any, thunkAPI) => {
  try {
    let apiData = await service.getCustomerCustomStatus();

    return apiData;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onSubmitUpdateCustomerCustomProfile = createAsyncThunk('customer_profile/custom/submit', async (params: any, thunkAPI) => {
  try {
    let apiData = await service.updateCustomerCustomProfile(params);

    return apiData;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
})

// Actions
export const { reset, patch, resetOption } = slice.actions;

// Export
export default slice.reducer;
