import { ActivityItemModel } from './activity_items';
import { ActivityMenuModel } from './activity_menu';
import { IncentiveModel } from './incentive';
import { MarketShareAcnModel } from './market_share_anc';
import { SalesPerformanceModel } from './sales_performance';
import { AssitantPerformanceModel } from './assitant_performance';
import { MSKSubAgentModel } from './msk_sub_agent';
import { Expose, Type } from 'class-transformer';
import { CallPlanOverviewModel } from '../report/call_plan';
import { OntradeModel } from './on_trade';

export class OverviewModel {
  constructor() {
    this.eActivityItems = [];
    this.activityItems = [];
    this.activityMenu = [];
  }

  @Type(() => IncentiveModel)
  incentive?: IncentiveModel;

  @Expose({ name: 'salesPerformance' })
  @Type(() => SalesPerformanceModel)
  salesPerformance?: SalesPerformanceModel;

  @Expose({ name: 'sumAssitantSalePerformanceData' })
  @Type(() => AssitantPerformanceModel)
  assitantPerformance?: AssitantPerformanceModel;

  @Expose({ name: 'marketShareACN' })
  @Type(() => MarketShareAcnModel)
  marketShareACN?: MarketShareAcnModel;

  @Expose({ name: 'mskSubAgent' })
  @Type(() => MSKSubAgentModel)
  mskSubAgent?: MSKSubAgentModel;

  @Expose({ name: 'callPlanDashboard' })
  @Type(() => CallPlanOverviewModel)
  callPlan?: CallPlanOverviewModel;

  @Expose({ name: 'todayActivityItems' })
  @Type(() => ActivityItemModel)
  activityItems: ActivityItemModel[];

  @Expose({ name: 'eActivityItems' })
  @Type(() => ActivityItemModel)
  eActivityItems: ActivityItemModel[];

  @Expose({ name: 'todayActivityMenu' })
  @Type(() => ActivityMenuModel)
  activityMenu: ActivityMenuModel[];

  @Expose({ name: 'powerBI' })
  @Type(() => String)
  powerBI?: String;

  @Expose({ name: 'saleDashboardOnChannel' })
  // @Type(() => OntradeModel)
  saleDashboardOnChannel?: {
    dataSalePerformance: OntradeModel[];
    name: string;
    updatetedDate: string
  }

  @Expose({ name: 'bPlusData' })
  @Type(() => SalesPerformanceModel)
  bPlusData?: SalesPerformanceModel

  @Expose({ name: 'updatetedDate' })
  @Type(() => Date)
  updated?: Date;
}
