import { Expose, Type } from "class-transformer";
import { ProfileModel } from "./profile";
import { MenuModel } from "./menu";
import { RoleModel } from "./role";

export class UserModel {
  constructor() {
    this.profile = new ProfileModel();
    this.menus = [];
    this.roles = [];
    this.isManagementView = false;
  }

  @Expose({ name: "userLogin" })
  @Type(() => ProfileModel)
  profile: ProfileModel;

  @Expose({ name: "menuItems" })
  @Type(() => MenuModel)
  menus: MenuModel[];

  @Expose({ name: "roleItems" })
  @Type(() => RoleModel)
  roles: RoleModel[];

  @Expose({ name: "isManagementView" })
  isManagementView: boolean;
}

export class SaleOrgModel {
  constructor() {
    this.selectedChannelId = undefined;
    this.selectedChannelName = undefined;
    this.selectedCompanyId = undefined;
    this.selectedCompanyName = undefined;
    this.selectedSaleOrgCriteriaId = undefined;
    this.selectedSaleOrgCriteriaName = undefined;
    this.selectedSaleOrgCriteriaCode = undefined;
    this.selectedSaleOrgCompanyCode = undefined;
    this.canAddCustomer = false
  }

  @Expose({ name: "selectedChannelId" })
  selectedChannelId: undefined;

  @Expose({ name: "selectedChannelName" })
  selectedChannelName: undefined;

  @Expose({ name: "selectedCompanyId" })
  selectedCompanyId: undefined;

  @Expose({ name: "selectedCompanyName" })
  selectedCompanyName: undefined;

  @Expose({ name: "selectedSaleOrgCriteriaId" })
  selectedSaleOrgCriteriaId: undefined;

  @Expose({ name: "selectedSaleOrgCriteriaName" })
  selectedSaleOrgCriteriaName: undefined;

  @Expose({ name: "selectedSaleOrgCriteriaCode" })
  selectedSaleOrgCriteriaCode: undefined

  @Expose({ name: "selectedSaleOrgCompanyCode" })
  selectedSaleOrgCompanyCode: undefined

  canAddCustomer:boolean
}
