import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ServiceStatus } from '@/models/enums/service';
import Service from '@/services';
import { RootState } from '@/stores';
import { ReportTabsModel } from '@/models/classes/report/tabs';
import { mergeObjects } from '@/utils/utils';

const service = new Service.ReportService();

// Interface
interface ReportState {
  fetch: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data: ReportTabsModel[];
  };
}

// Initialize State
const initialState: ReportState = {
  fetch: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: [],
  },
};

// Reducer
export const slice = createSlice({
  name: 'Report',
  initialState,
  reducers: {
    reset: () => initialState,
    patch: (state, action) => mergeObjects({ ...state }, action.payload),
  },
  extraReducers: (builder) => {
    // Fetch User
    builder.addCase(onFetchListOfDashboard.pending, (state) => {
      state.fetch.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchListOfDashboard.fulfilled, (state, action) => {
      state.fetch.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.fetch.data = action.payload;
        
      }
    });
    builder.addCase(onFetchListOfDashboard.rejected, (state, action) => {
      state.fetch.network.code = ServiceStatus.failed;
      state.fetch.network.error = (action.payload as string) || 'Failed to fetch data';
    });
  },
});

// Service
export const onFetchListOfDashboard = createAsyncThunk('reports/list/dashboard', async (_, thunkAPI) => {
  try {
    const state = (thunkAPI.getState() as RootState).authorization.organize.selected.sale.value;
    const apiData = await service.fetchListOfDashboard(state);

    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

// Actions
export const { patch, reset } = slice.actions;

// Export
export default slice.reducer;
