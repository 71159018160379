import qs from 'qs';
import { currentCompany } from './utils';
import "reflect-metadata";
import store from '@/stores'


/**
 * Extract param from ?param when param is either infornt or behide #
 * @param param param name to extract
 * @returns param value as string
 */
export const getParam = (param: string) => {
  const queryParams = qs.parse(window.location.hash.split('?')[1], {
    ignoreQueryPrefix: true,
  });
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get(param);

  let storedAccessToken = localStorage.getItem(param);
  console.log('storedAccessToken', storedAccessToken);
  if (typeof token === 'string') {
    return token;
  } else if (typeof queryParams?.[param] === 'string') {
    return queryParams[param] as string;
  } else {
    return false;
  }
};

/**
 * generate callback to program
 * @param path string path in url afer # ex. '/VisitNoPlane/detail'
 * @param queryString string query params after ? ex. 'param1=1&param2=2'
 * @returns
 */
export const generateCallBackURL = (path: string, queryString: string = '') => {
  // if (window.location.origin === 'file://') {
  let param = queryString === '' ? '?ShowPopUp=false' : '?ShowPopUp=false&' + queryString;
  return encodeURIComponent(window.location.origin + '/#' + path + param);
};
/**
 * generate callback to program
 * @param path string path in url afer # ex. '/VisitNoPlane/detail'
 * @param queryString string query params after ? ex. 'param1=1&param2=2'
 * @returns
 */
export const generateTechServCallBackURL = (path: string, queryString: string = '') => {
  // if (window.location.origin === 'file://') {
  if (window?.androidWebview) {
    //redirect for techserv intend
    let param = '&queryString=' + encodeURIComponent(queryString + '&timestamp=' + new Date().valueOf() + '&ShowPopUp=false');
    return encodeURIComponent(`techserv://subapp?appId=${process.env.REACT_APP_APP_ID}&subPath=${encodeURIComponent('#' + path)}` + param);
  } else {
    let param = queryString === '' ? '?ShowPopUp=false' : '?ShowPopUp=false&' + queryString;
    return encodeURIComponent(window.location.origin + '/#' + path + param);
  }
};
interface ExtenalParam {
  // teamcode?: string
}

function redirectExternal(baseUrl: string, data: any = undefined) {
  if (baseUrl == '') return false;
  const orgData = store.getState().authorization.auth.saleOrg
  const teamcode = orgData?.selectedSaleOrgCriteriaCode;
  const companyCode = orgData?.selectedSaleOrgCompanyCode

  const token = localStorage.getItem('accessToken');

  let param = `salesTeamCode=${teamcode}&companyCode=${companyCode}&callback=${generateTechServCallBackURL('')}&token=${token}`;
  //This is take order if there is extra data
  if (data !== undefined && !data?.salesOrderDate) {
    param = `salesTeamCode=${teamcode}&companyCode=${companyCode}&customerId=${data.customerId}&paymentType=${data.type}&callback=${data.callback}&token=${token}`;
  }

  if (data?.salesOrderDate) {
    param = `salesOrderDate=${data?.salesOrderDate}&salesTeamCode=${teamcode}&companyCode=${companyCode}&callback=${generateTechServCallBackURL('')}&token=${token}`;
  }

  const link = baseUrl + '?' + param;
  console.log('redirect to: ', link);
  window.open(link, '_self');
}


/**
 * Redirect to external "เบิกสินค้า"
 */
export const goToWithDrawApp = () => {
  // const teamcode = data?.teamcode ?? '12027'
  const baseUrl = process.env.REACT_APP_WITHDRAW_URL!;
  redirectExternal(baseUrl)
};

/**
 * Redirect to external "สินค้าบนรถ"
 */
export const goToVanApp = () => {
  // const teamcode = data?.teamcode ?? '12027'
  const baseUrl = process.env.REACT_APP_VAN_URL!;
  redirectExternal(baseUrl)
};

/**
 * Redirect to good history
 * @param salesOrderDate string date std format "2024-01-01"
 */
export const goToGoodsHistory = (salesOrderDate:string) => {
  const baseUrl = process.env.REACT_APP_GOODS_HISTORY_URL!;
  redirectExternal(baseUrl,{salesOrderDate})
}

export const goToReport = () => {
  const baseUrl = process.env.REACT_APP_GOODS_REPORT_URL!;
  redirectExternal(baseUrl)
}

// ?salesOrderDate=2024-11-17&salesTeamCode=12027&companyCode=4900&&callback=https:%2F%2Fsit.imaginic.dev%2F%23%2Fvisitnoplan%2Fvisit%2F503218%3FShowPopUp%3Dfalse&token=

interface takeorder_param extends ExtenalParam {
  type: 'cash' | 'credit' | 'future';
  customerId: string;
  callback: string;
}
export const takeorder = (data: takeorder_param) => {
  // const teamcode = data?.teamcode ?? '12027'
  let baseUrl = '';
  const type = data.type;
  if (type == 'cash') {
    baseUrl = process.env.REACT_APP_TAKE_ORDER_CASH_URL!;
  } else if (type == 'credit') {
    baseUrl = '';
  }
  redirectExternal(baseUrl, data)
  // if (baseUrl == '') return false;

  // const token = localStorage.getItem('accessToken');
  // const param = `salesTeamCode=${teamcode}&companyCode=${currentCompany().code}&customerId=${data.customerId}&paymentType=${type}&callback=${data.callback}&token=${token}`;
  //let url = `vsms_android://com.thaibev.vsms/Customer?EmployeeId=${payload.employeeId}&CustomerCode=${payload.customerCode}&CallBackURL=${encodeURIComponent(callBack)}`;
  // const link = baseUrl + '?' + param;
  // console.log('redirect to: ', link);
  // window.open(link, '_self');
};

interface vsms_param extends ExtenalParam {
  customerCode: string;
  employeeId: string;
  callback: string;
}
export const vsmsCompanyList = () => ["7800", "5700"] // default ["7800", "5700"]
export const toVSMS = (data: vsms_param) => {
  let link = `vsms_android://com.thaibev.vsms/Customer?EmployeeId=${data.employeeId}&CustomerCode=${data.customerCode}&CallBackURL=${encodeURIComponent(data.callback)}`;
  console.log('redirect to: ', link);
  // window.open(link, '_self');
  if (!window?.androidWebview) {
    return console.log("%c Protocal not support please open on techserv", 'color:red; font-style:bold')
  } 
  window.androidWebview.navigate(link, {
    shouldRedirect: true,
  });
}

