import { getMimeType } from '@/utils/file';
import axios from 'axios';
let currentAPI = process.env.REACT_APP_FILE_BASE_DOMAIN;

class APIFile {
  async get(id: any) {
    if (!Number.isInteger(parseInt(id))) {
      return null;
    } else {
      try {
        if (window.isOffline) {
          return null;
        } else {
          const response = await axios({
            url: `${currentAPI}/DocsAPIx64/api/v1/Document/PreviewFile?folderFileId=${id}&domainId=${process.env.REACT_APP_DOMAIN_ID}`,
            method: 'GET',
            responseType: 'blob',
          });

          const url = window.URL.createObjectURL(new Blob([response.data]));
          return url;
        }
      } catch (error) {
        throw error;
      }
    }
  }
  //Upload image
  async upload(image: number | string, concurrent?: number | string) {
    try {
      let fileName = concurrent ? Date.now() + '_' + concurrent : Date.now();
      let body = JSON.stringify({
        folderId: process.env.REACT_APP_FOLDER_ID,
        domainId: process.env.REACT_APP_DOMAIN_ID,
        file: image,
        fileName
      });

      let headers = {
        accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      };

      let url = currentAPI + '/DocsAPIx64/api/v1/Document/UploadBase64';

      const res = await axios.post(url, body, {
        headers: headers,
      });
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Upload file
   * @param file File to upload
   * @param id userId
   * @returns
   */
  async uploadFile(file: File, id?: number | string) {
    try {
      const folderId = process.env.REACT_APP_FOLDER_FILE_ID!;
      const domainId = process.env.REACT_APP_DOMAIN_ID!;

      let formData = new FormData();
      formData.append('folderId', folderId);
      formData.append('domainId', domainId);
      formData.append('file', file);
      if (id !== undefined) formData.append('userId', id.toString());

      let headers = {
        accept: '*/*',
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      };

      let url = currentAPI + '/DocsAPIx64/api/v1/Document/Upload';

      const res = await axios.post(url, formData, {
        headers: headers,
      });
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Download file and save to system storage
   * @param id File to download
   * @returns no return
   */
  async downloadFile(id: any) {
    if (!Number.isInteger(parseInt(id))) {
      return null;
    } else {
      try {
        const response = await axios({
          url: `${currentAPI}/DocsAPIx64/api/v1/Document/DownLoadFile?folderFileId=${id}&domainId=${process.env.REACT_APP_DOMAIN_ID}`,
          method: 'GET',
          responseType: 'blob',
        });

        console.log('disposition', response.headers['Content-Type']);

        const extension = response.headers['Content-Type'] ? '.' + getMimeType(response.headers['Content-Type']) : '';

        let filename = `business_review_actual-${id}${extension}`;

        let url = window.URL.createObjectURL(response.data);
        let a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
      } catch (error) {
        throw error;
      }
    }
  }
}

export default APIFile;
