import axios from 'axios';
import * as qs from 'qs';

//Services
export class ReportDashboardBPlusApi {
  /** BPLUS */
  getSalePerformance = async (type: string | any, searchId: number | any, regionId: number | any) => {
    let params = {
      type: type,
      searchId: searchId,
      regionId: regionId,
    };
    let path = `/api/DashBoard/BPlus?${qs.stringify(params)}`;
    const config = {
      method: 'get',
      url: path,
      headers: {
        ByPassAuth: true,
      },
    };
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios(config);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  };
  /** OLD Sale Dashboard */
  getDashboardSaleReceiveMoney = async (params: any = {}) => {
    let path = `/api/DashBoard/GetDashboardSaleReceiveMoney?${qs.stringify(params)}`;
    const config = {
      method: 'get',
      url: path,
      headers: {
        ByPassAuth: true,
      },
    };
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios(config);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  };
  /** OLD Sale Dashboard */
  getDashboardRemainCarry = async (type: string | any, searchId: number | any, regionId: number | any) => {
    let params = {
      type: type,
      searchId: searchId,
      regionId: regionId,
    };

    let path = `/api/DashBoard/GetDashboarRemainCarry?${qs.stringify(params)}`;
    const config = {
      method: 'get',
      url: path,
      headers: {
        ByPassAuth: true,
      },
    };
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios(config);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  };
  /** OLD Sale Dashboard */
  getDashBoardWareHouse = async (type: string | any, searchId: number | any, regionId: number | any) => {
    let params = {
      type: type,
      searchId: searchId,
      regionId: regionId,
    };

    let path = `/api/DashBoard/GetDashBoardWareHouse?${qs.stringify(params)}`;
    const config = {
      method: 'get',
      url: path,
      headers: {
        ByPassAuth: true,
      },
    };
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios(config);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  };
  /** BPLUS */
  getDashboardSellOut = async (type: string | any, searchId: number | any, regionId: number | any) => {
    let params = {
      type: type,
      searchId: searchId,
      regionId: regionId,
    };
    let path = `/api/DashBoard/BPlusSellOut?${qs.stringify(params)}`;
    const config = {
      method: 'get',
      url: path,
      headers: {
        ByPassAuth: true,
      },
    };
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios(config);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  };
}
